<template>
  <component
    :is="to ? 'b-link' : 'article'"
    class="app-card bg-white"
    append
    :to="to"
  >
    <div class="position-relative">
      <b-img
        v-if="src"
        fluid-grow
        :src="`${src}?tr=h-200,w-400,fo-auto`"
        alt="Image"
        :blank-color="color"
        :style="grayscale && 'filter: grayscale(100%)'"
      />
      <b-img v-else fluid-grow height="100" blank :blank-color="color" />
      <slot name="overlay" />
    </div>

    <div class="d-flex flex-column px-3 py-3 flex-grow-1">
      <div class="">
        <div class="d-flex justify-content-between align-items-baseline">
          <h7 class="font-weight-semibold primary" :class="{ 'text-muted': grayscale }">
            {{ title }}
          </h7>
        </div>

        <p
          class="text-muted mb-0"
          v-if="description"
          :style="fullText ? 'white-space: pre-line;' : ''"
        >
          {{ truncated(description, descriptionLength) }}
        </p>

        <b-button
          v-if="description.length > descriptionLength"
          block
          size="sm"
          variant="link"
          class="d-flex align-items-center justify-content-center"
          @click="fullText = !fullText"
        >
          <span class="mr-1 text-nowrap">
            {{ fullText ? $t("read_less") : $t("read_more") }}
          </span>
          <b-icon-chevron-down class="animate" :class="{ flip: fullText }" />
        </b-button>
      </div>

      <slot />
    </div>
  </component>
</template>

<script>
import { BIconChevronDown } from "bootstrap-vue"
export default {
  components: { BIconChevronDown },
  props: {
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    buttonText: { type: String, default: "" },
    grayscale: { type: Boolean, default: false },
    descriptionLength: { type: Number, default: 100 },
    src: { type: String, default: null },
    to: { type: String, default: null },
    color: { type: String, default: null },
  },
  data() {
    return {
      fullText: false,
    }
  },
  methods: {
    truncated(text, length) {
      if (this.descriptionLength === undefined || this.fullText) {
        return text
      } else {
        return text.length > length ? text.slice(0, length) + "..." : text
      }
    },
  },
}
</script>
