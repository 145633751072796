<template>
  <b-container class="my-2 my-sm-5" fluid>
    <b-button to="/challenge" class="mb-2 text-dark" variant="transparent">
      &lt; {{ $t("go_back") }}
    </b-button>

    <h1 class="mb-3">{{ $t("missions.add_mission") }}</h1>

    <b-overlay :show="loading">
      <b-row cols="1" cols-md="1" cols-lg="2" cols-xl="3">
        <b-col v-for="mission in challenges" :key="mission.id" class="mb-4 mt-4">
          <MissionCard
            :title="mission.name"
            :description="mission.description"
            :src="`${mission.file_path}${mission.file_name}`"
            style="min-height: 450px;"
          >
            <template>
              <div class="mt-3">
                <div class="d-flex align-items-center">
                  <b-icon-flag-fill class="mr-1" />
                  <span>{{ $t("missions.challenge") }}:</span>
                </div>
                <div class="text-terciary">
                  {{ mission.description_exhortion }}
                </div>
              </div>
              <div class="d-flex flex-column flex-grow-1 justify-content-end">
                <hr class="w-100 mb-2" />
                <b-button
                  variant="primary"
                  block                  
                  @click="joinChallenge(mission)"
                >
                  {{ $t("missions.add_mission") }}
                  
                </b-button>
              </div>
            </template>

            <template #overlay>
              <small
                class="position-absolute card-badge"
                style="top: 0.7rem; left: 0.7rem;"
              >
                {{ mission.domain_name }}
              </small>
              <div
                class="position-absolute bg-primary text-white px-2 py-1 d-flex flex-column align-items-center"
                style="bottom: 0; right: 0; border-top-left-radius: 0.7rem"
              >
                <small style="line-height: 1">{{ $t("points") }}</small>
                <b style="line-height: 1; margin-bottom: 3px">
                  {{ mission.total_points }}
                </b>
                <div
                  class="bg-white text-body p-1 rounded-circle"
                  style="line-height: 0"
                >
                  <b-icon-star font-scale="0.75" />
                </div>
              </div>
            </template>
          </MissionCard>
        </b-col>
        <b-col v-if="!challenges.length" class="text-center mx-auto">
          {{ $t("missions.no_new") }}
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import axios from "axios"
import MissionCard from "@/components/MissionCard.vue"
import { BIconFlagFill, BIconStar } from "bootstrap-vue"

export default {
  components: { MissionCard, BIconFlagFill, BIconStar },
  data() {
    return {
      /** @type {Array<any>} */
      challenges: [],
      loading: false,
      error: "",
    }
  },
  created() {
    this.loadRecommended()
  },
  methods: {
    async loadRecommended() {
      this.loading = true
      try {
        const response = await axios.get(`/challenges/new`)
        this.challenges = response.data.data ?? []
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name} ${message}`,
        })
      } finally {
        this.loading = false
      }
    },
    async joinChallenge(mission) {
      this.loading = true
      try {
        const response = await axios.post(`/challenge/join`, {
          challenge_id: mission.id,
        })
        if (response.data.data !== true) throw Error("Invalid response")
        this.$store.dispatch("addToastMessage", {
          type: "success",
          text: this.$t("missions.mission_added"),
        })
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.loading = false
        this.loadRecommended()
        this.$emit("added")
      }
    },
  },
}
</script>


<style scoped>
.custom-row-spacing > .row > [class^='col-'],
.custom-row-spacing > .row > [class*=' col-'] {
  margin-bottom: 30px; /* Adjust the vertical space between rows */
}
</style>